
import "./App.css";
import logo from "./logo.svg";
import "./fonts.css";
import "./Light.css";
import Main from "./Main";
import { Canvas } from "react-three-fiber";
import Particles from "./Particles";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Loader from "./Loader";
import { useSpring } from "react-spring";
import Details from "./Details";
import { useTranslation } from "react-i18next";
import MP3Player from "./MP3Player";

const App = () => {
  const [projects, setProjects] = useState();
  const [exp, setExp] = useState();
  const [skills, setSkills] = useState();
  const [theme, setTheme] = useState('dark')
  const { t, i18n } = useTranslation();

  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    axios
      .get(
        "https://us-central1-portfolio-385821.cloudfunctions.net/getProjects"
      )
      .then((response) => {
        console.log(response.data);
        setProjects(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(
        "https://us-central1-portfolio-385821.cloudfunctions.net/getExperience"
      )
      .then((response) => {
        console.log(response.data);
        setExp(response.data.filter((item) => item.visible));
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(
        "https://us-central1-portfolio-385821.cloudfunctions.net/getSkills"
      )
      .then((response) => {
        console.log("skills :", response.data);
        setSkills(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const [selectedItem, setSelectedItem] = useState();

  const [activeLang, setActiveLang] = useState(i18n.language);
  const [selected, setSelected] = useState(0);

  const handleNavClick = (id) => {
    setSelected(id);
  };

  return (
    <div className={`App ${theme}`}>.<div className="new-nav">

      <div>
        <button className={selected === 0 ? "active-nav-btn" : ""} onClick={() => handleNavClick(0)}>{t('home')}</button>

      </div>
      <div>
        <button className={selected === 1 ? "active-nav-btn" : ""} onClick={() => handleNavClick(1)}>{t('experience')}</button>
      </div>
      <div>
        <button className={selected === 2 ? "active-nav-btn" : ""} onClick={() => handleNavClick(2)}>{t('projects')}</button>

      </div>
      <div>
        <button className={selected === 3 ? "active-nav-btn" : ""} onClick={() => handleNavClick(3)}>{t('skills')}</button>

      </div>
      <div>
        <button className={selected === 4 ? "active-nav-btn" : ""} onClick={() => handleNavClick(4)}>{t('contact')}</button>
      </div>

    </div>
      {/* <button id="dark-btn" onClick={() => { setTheme('dark'); document.querySelector('body').style.backgroundColor = "#0c0c0c"; document.querySelector('body').style.color = "#fff"; document.querySelector('#viz').style.opacity = 0.5; document.querySelector("#viz").style.filter = "none" }}>
        <div className={`theme-square ${theme === 'dark' ? 'active-theme-square' : ''}`}></div>
        Dark
      </button>
      <button id="light-btn" onClick={() => { setTheme('light'); document.querySelector('body').style.backgroundColor = "#e6e6e6"; document.querySelector('body').style.color = "#0d0d0d"; document.querySelector('#viz').style.opacity = 0.2; document.querySelector("#viz").style.filter = "invert(100%)" }}>
        <div className={`theme-square ${theme === 'light' ? 'active-theme-square' : ''}`}></div>
        Light
      </button>
      <div className="separation"></div> */}
      {/* <div className="translation">
        <button id="en-btn" onClick={() => switchLanguage('en')}>
          <div className={`lang-square ${i18n.language === 'en' ? 'active-lang-square' : ''}`}></div>
          EN
        </button>
        <button id="fr-btn" onClick={() => switchLanguage('fr')}>
          <div className={`lang-square ${i18n.language === 'fr' ? 'active-lang-square' : ''}`}></div>
          FR
        </button>
      </div> */}
      <MP3Player />
      <div className="main">

        <Main projects={projects} exp={exp} skills={skills} selectedItem={selectedItem} setSelectedItem={setSelectedItem} selected={selected}
          setSelected={setSelected} />
      </div>

      <Details selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
    </div>
  );
};

export default App;