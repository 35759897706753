import React from 'react';
import { useEffect } from 'react';

const formatDate = date => {
  const options = { year: 'numeric', month: 'long' };
  return date.toLocaleDateString(undefined, options);
};

const ExpItem = ({ experience,selectedItem,setSelectedItem }) => {
  const {
    mission,
    role,
    company,
    start,
    client,
    end
  } = experience;

  // Convert Firestore timestamp to JavaScript Date object
  const startDate = new Date(start._seconds * 1000);
  const endDate = end ? new Date(end._seconds * 1000) : null;

  // Format the dates, considering that endDate might be null
  const formattedStart = formatDate(startDate);
  const formattedEnd = endDate ? formatDate(endDate) : 'Present';

  /* useEffect(() => {
    const links = document.querySelectorAll(".clickable");
    links.forEach((link)=>{
      link.addEventListener('click',()=>{
        
      })
    })
  }, []) */
  
  const handleClick = () => {
    document.querySelector("#viz").classList.add("hide")
    document.querySelector(".player").classList.add("hide")
    document.querySelector("#audio-btn").classList.add("hide")
    document.querySelector(".main").classList.add("hide")
    document.querySelector(".exp-details").classList.add("visible")
    setSelectedItem(experience)
  }
  


  return (
    <div>
    <a onClick={handleClick}>
      <p className="project-type">
        {formattedStart} - {formattedEnd} / {client}
      </p>
      <p className="project-name">{role}</p>
    </a>
    
    </div>
  );
};

export default ExpItem;
