import React from "react";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="header">
      <h1>Khamri Achraf</h1>
      <p>{t("title")}</p>
    </div>
  );
};

export default Header;
