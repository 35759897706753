import React, { useMemo, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  Color,
  MeshPhongMaterial,
  MeshStandardMaterial,
  PointLight,
} from "three";
import { useSpring, animated } from "@react-spring/three";
import { MeshDistortMaterial } from "@react-three/drei";

const AnimatedPointLight = animated(PointLight);

function Box(props) {
  // This reference will give us direct access to the mesh
  const mesh = useRef();
  // Set up state for the hovered and active state
  const [hovered, setHover] = useState(false);
  const [active, setActive] = useState(false);
  const [goingUp, setGoingUp] = useState(false);
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame(() => {
    mesh.current.rotation.y += props.y_speed;
  });

  const material = useMemo(
    () =>
      new MeshPhongMaterial({
        color: new Color("#000"),
        emissive: new Color(props.color),
        specular: new Color("#000"),
        shininess: 1,
        flatShading: true,
        wireframe: true,
        transparent: 1,
        opacity: 0.1,
      })
  );
  return (
    <mesh ref={mesh}>
      <sphereGeometry args={[2.8, 15, 15, 0, Math.PI * 2, 0, Math.PI]} />
      <meshStandardMaterial color={"white"} wireframe={true} />
    </mesh>
  );
}

function Particles(props) {
  return (
    <>
      <Canvas>
        <ambientLight intensity={0} />
        <animated.pointLight
          color={"#fff"}
          intensity={0.3}
          position={props.position}
        />
        <Box
          position={[-1.2, 0, 0]}
          x_speed={props.x_speed}
          y_speed={props.y_speed}
          dark={props.dark}
        />
      </Canvas>
    </>
  );
}

export default Particles;
