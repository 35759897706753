import { Group, Slider, Stack, Tabs, rem } from "@mantine/core";
import {
  IconMessageCircle,
  IconPhoto,
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconPlayerTrackNext,
  IconPlayerTrackNextFilled,
  IconPlaylist,
  IconSettings,
  IconVolume,
  IconVolume2,
  IconX,
} from "@tabler/icons-react";
import { IconPlayerPause, IconPlayerPlay } from "@tabler/icons-react";
import React, { useState, useRef, useEffect } from "react";

const bank = [
  {
    title: "Senbon Zakura",
    author: "Kurousa-P",
    category: "piano",
    src: "/Senbon.mp3",
  },
  {
    title: "Akatonbo",
    author: "Kōsaku Yamada",
    category: "piano",
    src: "/dragonfly.mp3",
  },
  {
    title: "Swan Lake",
    author: "Tchaikovsky",
    category: "piano",
    src: "/swan.mp3",
  },
  {
    title: "Nocturne op.9 No.2",
    author: "Frédéric Chopin",
    category: "piano",
    src: "/nocturne.mp3",
  },
  {
    title: "Clair de Lune",
    author: "Debussy",
    category: "piano",
    src: "/lune.mp3",
  },
  {
    title: "Merry-Go-Round of Life",
    author: "Joe Hisaishi",
    category: "piano",
    src: "/merry.mp3",
  },
  {
    title: "Mazurka No. 4 in A minor",
    author: "Frédéric Chopin",
    category: "violin",
    src: "/Mazurkas.mp3",
  },
];

function MP3Player() {
  const [currentTrackIndex, setCurrentTrackIndex] = useState(
    Math.floor(Math.random() * bank.length)
  );
  const [isPlaying, setIsPlaying] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [category, setCategory] = useState("Violin");
  const [playlist, setPlaylist] = useState(bank);
  const [volume, setVolume] = useState(0.3);
  const audioRef = useRef(null);

  const playPauseHandler = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const nextHandler = () => {
    const nextTrack = (currentTrackIndex + 1) % playlist?.length;
    setCurrentTrackIndex(nextTrack);
    if (isPlaying) {
      audioRef.current.pause();
      audioRef.current.load();
      audioRef.current.play();
      audioRef.current.play();
    }
  };

  const selectTrack = (index) => {
    if (index !== currentTrackIndex) {
      setCurrentTrackIndex(index);
      if (isPlaying) {
        audioRef.current.pause();
        audioRef.current.load();
        audioRef.current.play();
      }
    }
    setShowDetails(false);
  };

  useEffect(() => {
    const onAudioEnded = () => {
      nextHandler();
    };

    const audioEl = audioRef.current;
    audioEl.addEventListener("ended", onAudioEnded);

    return () => {
      audioEl.removeEventListener("ended", onAudioEnded);
    };
  }, [currentTrackIndex]);

  useEffect(() => {
    setPlaylist(bank);
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  return (
    <div className="player">
      {showDetails && (
        <div className="playlist">
          <Tabs defaultValue="piano">
            <Tabs.List>
              <Tabs.Tab value="piano">Piano</Tabs.Tab>
              <Tabs.Tab value="violin">Violin</Tabs.Tab>
              <Tabs.Tab value="all">All</Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="piano" pt="xs">
              {bank
                ?.filter((song) => song.category === "piano")
                .map((track, index) => (
                  <div key={index}>
                    <div
                      className="item"
                      onClick={() => {
                        selectTrack(playlist.indexOf(track));
                      }}
                    >
                      {track.title} - {track.author}
                    </div>
                  </div>
                ))}
            </Tabs.Panel>

            <Tabs.Panel value="violin" pt="xs">
              {bank
                ?.filter((song) => song.category === "violin")
                .map((track, index) => (
                  <div key={index}>
                    <div
                      className="item"
                      onClick={() => {
                        selectTrack(playlist.indexOf(track));
                      }}
                    >
                      {track.title} - {track.author}
                    </div>
                  </div>
                ))}
            </Tabs.Panel>

            <Tabs.Panel value="all" pt="xs">
              {bank?.map((track, index) => (
                <div key={index}>
                  <div
                    className="item"
                    onClick={() => {
                      selectTrack(playlist.indexOf(track));
                    }}
                  >
                    {track.title} - {track.author}
                  </div>
                </div>
              ))}
            </Tabs.Panel>
          </Tabs>
        </div>
      )}
      {showDetails && (
        <div className="volume-control">
          <IconVolume2 size="1rem" />
          <Slider
            label={null}
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={setVolume}
            style={{ width: "calc(100% - 60px)" }}
            styles={(theme) => ({
              thumb: {
                height: "20px",
                width: "5px",
                borderRadius: "0px",
                borderWidth: "0px",
              },
            })}
          />
          <IconVolume size="1rem" />
        </div>
      )}
      <div className="current">
        <h3>{`${playlist[currentTrackIndex].title} \\\\ ${playlist[currentTrackIndex].author}`}</h3>
        <button onClick={playPauseHandler}>
          {isPlaying ? (
            <IconPlayerPauseFilled size="0.8rem" />
          ) : (
            <IconPlayerPlayFilled size="0.8rem" />
          )}
        </button>
        <button onClick={nextHandler}>
          <IconPlayerTrackNextFilled size="0.8rem" />
        </button>

        <audio ref={audioRef} src={playlist[currentTrackIndex].src}></audio>
      </div>
    </div>
  );
}

export default MP3Player;
