import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

const Contact = () => {
  return (
    <div className="home contact">
      <div className="contact-form">
        <a href="https://docs.google.com/forms/d/1bhHHczeeMo1CpJsC_h3TmUe_YolMZx-KHpZfPaNc0ds" className="p-1 project-name">
          Contact Form <FaExternalLinkAlt className="external-icon"/>
        </a>
        <a href="https://github.com/khamriAchraf" className="p-2 project-name">
          Github<FaExternalLinkAlt className="external-icon"/>
        </a>
        <a href="www.linkedin.com/in/achraf-khamri-5430891b0" className="p-3 project-name">
          Linkedin <FaExternalLinkAlt className="external-icon"/>
        </a>
        
      </div>
    </div>
  );
};

export default Contact;
