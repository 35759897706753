import React from 'react'

const Chip = (props) => {
  return (
    <div className='chip'>
        <p>
            {props.content}
        </p>
    </div>
  )
}

export default Chip