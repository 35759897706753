import React, { useEffect, useState } from "react";
import Header from "./Header";
import Nav from "./Nav";
import Content from "./Content";
import Particles from "./Particles";
import { useSpring, animated } from "@react-spring/web";
import axios from "axios";
import Loader from "./Loader";
import { useTranslation } from 'react-i18next';
import NewHome from "./NewHome";

const Main = (props) => {
  const [selected, setSelected] = useState(0);
  const [x_speed, setX_speed] = useState(0.00001);
  const [y_speed, setY_speed] = useState(0.0005);
  const [dark, setDark] = useState(true);
  const [position, setPosition] = useState([0, 0, 0]);
  const [opacity, setOpacity] = useState(0.2);

  const { t } = useTranslation();
  const animateOpacity = (x) => {
    setOpacity(0);
  };

  const { springOpaciy } = useSpring({
    springPosition: opacity,
    config: { duration: 1000 }, // configure the duration for 2 seconds
  });

  const animate = (x) => {
    setPosition(x);
  };

  const { springPosition } = useSpring({
    springPosition: position,
    config: { duration: 1000 }, // configure the duration for 2 seconds
  });

  const handleNavClick = (id) => {
    switch (id) {
      case 0:
        animate([5, 5, 0]);
        break;
      case 1:
        animate([6, 2, 0]);
        break;
      case 2:
        animate([0, 0, 5]);
        break;
      case 3:
        animate([0, 5, 5]);
        break;
      case 4:
        animate([5, 0, 5]);
        break;
      default:
        break;
    }
    setSelected(id);
  };
  const handleThemeClick = () => {
    setDark((s) => !s);
  };

  useEffect(() => {
    setTimeout(() => {
      document.querySelector(".loader").style.opacity = 0;
      document.querySelector(".loader").style.zIndex = -10;
      document.querySelector(".main").style.border = "1px solid white";
    }, 1000);
    setTimeout(() => {
      document.querySelector(".loader-main .name").style.opacity = 0;
      animate([0, 0, 5]);
    }, 900);
    setTimeout(() => {
      document.querySelector(".opacity").style.opacity = 1;
      document.querySelector("#viz").style.opacity = 0.5;
      document.querySelector(".player").style.opacity = 1;
      animate([5, 5, 0]);
    }, 1100);
  }, []);

  return (
    <>
      <div className="opacity">
        {props.selected === 0 ? <NewHome setSelected={props.setSelected} />: <Header />}
        
        
        <Content
          selectedItem={props.selectedItem}
          setSelectedItem={props.setSelectedItem}
          selected={props.selected}
          setSelected={props.setSelected}
          projects={props.projects}
          exp={props.exp}
          skills={props.skills}
        />
        <div className="particles-parent">
          {/* <Particles
            x_speed={x_speed}
            y_speed={y_speed}
            dark={dark}
            position={springPosition}
          /> */}
        </div>
      </div>
      <animated.div className="loader" style={{ opacity: springOpaciy }}>
        <Loader />
      </animated.div>
    </>
  );
};

export default Main;
