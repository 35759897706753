import React from "react";
import Home from "./Home";
import Projects from "./Projects";
import Info from "./Info";
import Contact from "./Contact";
import Exp from "./Exp";
import NewHome from "./NewHome";

const Content = (props) => {
  return (
    <div className="content">
      {props.selected === 1 && (
        <Exp
          selectedItem={props.selectedItem}
          setSelectedItem={props.setSelectedItem}
          exp={props.exp}
        />
      )}
      {props.selected === 2 && <Projects projects={props.projects} selectedItem={props.selectedItem}
          setSelectedItem={props.setSelectedItem}/>}
      {props.selected === 3 && <Info skills={props.skills} />}
      {props.selected === 4 && <Contact />}
    </div>
  );
};

export default Content;
