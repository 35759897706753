import React from 'react'

const Loader = () => {
  return (
    <div className='loader-main'>
        <h1 className='name'>Khamri Achraf</h1>
        <h1 className='sub'>Portfolio</h1>
    </div>
  )
}

export default Loader