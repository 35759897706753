import React from "react";
import Chip from "./Chip";

const Info = (props) => {
  console.log(props.skills);

  return (
    <div className="projects">
      {props.skills &&
        props.skills.map((skillcat) => {
          return (
            <div className="skill-group">
              
              <p className="project-type skill-title">{skillcat.category}</p>

              <div className="chip-container">
                {skillcat &&
                  skillcat.skills.map((skill) => {
                    return (
                      <>
                        <Chip content={skill} />
                      </>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Info;
