import axios from "axios";
import React from "react";
import { useState } from "react";
import ProjectItem from "./ProjectItem";
import { Divider } from "@mantine/core";

const Projects = (props) => {
  const [projects, setProjects] = useState(props.projects);

  const sortedProjects = [...projects]?.sort((a, b) => {
    return b.start._seconds - a.start._seconds;
  });
  console.log(sortedProjects);

  return (
    <div className="projects">
      {sortedProjects &&
        sortedProjects?.map((project, index) => {
          return (
            <React.Fragment key={project.name}>
              {index !== 0 && <Divider color="#f2f2f2" />}
              <ProjectItem
                selectedItem={props.selectedItem}
                setSelectedItem={props.setSelectedItem}
                project={project}
                index={index}
                max={sortedProjects.length}
              />
            </React.Fragment>
          );
        })}
    </div>
  );
};

export default Projects;
