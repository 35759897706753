import { Group } from "@mantine/core";
import { IconArrowRight, IconChevronRight } from "@tabler/icons-react";
import React, { useState } from "react";
import { useEffect } from "react";

const formatDate = (date) => {
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString(undefined, options);
};

const ProjectItem = ({
  project,
  selectedItem,
  setSelectedItem,
  index,
  max,
}) => {
  const { mission, role, company, start, client, end, field, year } = project;

  // Convert Firestore timestamp to JavaScript Date object
  const startDate = new Date(start._seconds * 1000);
  const endDate = end ? new Date(end._seconds * 1000) : null;

  // Format the dates, considering that endDate might be null
  const formattedStart = formatDate(startDate);
  const formattedEnd = endDate ? formatDate(endDate) : "Present";
  const realIndex = max - index;

  const handleClick = () => {
    document.querySelector("#viz").classList.add("hide");
    document.querySelector(".player").classList.add("hide");
    document.querySelector("#audio-btn").classList.add("hide");
    document.querySelector(".main").classList.add("hide");
    document.querySelector(".new-nav").classList.add("hide");
    document.querySelector(".exp-details").classList.add("visible");
    setSelectedItem(project);
  };

  const [hovered, setHovered] = useState(false);

  return (
    <Group
      style={{
        width: "100%",
        height: "150px",
        paddingLeft: "50px",
        paddingRight: "50px",
      }}
      onClick={handleClick}
      className="project-item"
      align="center"
      position="apart"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <p
        className="project-index"
        style={{
          fontSize: "18px",
          opacity: 0.8,
          paddingBottom: "10px",
          borderBottom: "1px solid white",
        }}
      >
        0{realIndex}
      </p>
      <div>
        <p className="project-name">{mission}</p>
        <p className="project-type">
           {field} - {year}
        </p>
      </div>
    </Group>
  );
};

export default ProjectItem;
