import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import Chip from "./Chip";
import Particles from "./Particles";
import {
  IconArrowAutofitContent,
  IconArrowLeft,
  IconBrandGithub,
} from "@tabler/icons-react";
import { IconEye } from "@tabler/icons-react";

const formatDate = (date) => {
  const options = { year: "numeric", month: "long" };
  return date.toLocaleDateString(undefined, options);
};

const Details = (props) => {
  const handleClose = () => {
    document.querySelector("#viz").classList.remove("hide");
    document.querySelector(".player").classList.remove("hide");
    document.querySelector(".main").classList.remove("hide");
    document.querySelector(".new-nav").classList.remove("hide");
    document.querySelector(".exp-details").classList.remove("visible");
    props.setSelectedItem();
  };

  console.log(props.selectedItem);

  return (
    <div className="exp-details">
      {props.selectedItem && (
        <div className="exp-header">
          <IconArrowLeft
            size={40}
            stroke={1}
            onClick={handleClose}
            className="close-icon"
          />
          <div className="exp-row">
            {props.selectedItem.client && (
              <div className="flex-row">
                <p className="exp-row-title">Client </p>

                <img className="client-logo" src={props.selectedItem.logo} />
                <p className="exp-row-text">{props.selectedItem.client}</p>
              </div>
            )}
            {props.selectedItem.field && (
              <div className="flex-row">
                <p className="exp-row-title">Type </p>
                <p className="exp-row-text">{props.selectedItem.field}</p>
              </div>
            )}
            {props.selectedItem.year && (
              <div className="flex-row">
                <p className="exp-row-title">Year </p>
                <p className="exp-row-text">{props.selectedItem.year}</p>
              </div>
            )}
          </div>
        </div>
      )}

      {props.selectedItem && (
        <div className="exp-content">
          <div className="flex-row exp-mission">
            <p className="">{props.selectedItem.mission}</p>
            <div>
              <IconEye
                size={40}
                stroke={1}
                onClick={handleClose}
                className="close-icon"
              />
              <IconBrandGithub
                size={40}
                stroke={1}
                onClick={handleClose}
                className="close-icon"
              />
            </div>
          </div>
          <div className="exp-tasks">
            {props.selectedItem.tasks.map((task) => {
              return <p>{task}</p>;
            })}
          </div>
          <div className="chip-container-details">
            {props.selectedItem.keywords.map((keyword) => {
              return <Chip content={keyword}  />;
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Details;
