import React from "react";
import ExpItem from "./ExpItem";
import "./Resume.css";

const Exp = ({ exp,selectedItem,setSelectedItem }) => {
  console.table(exp);
  const sortedExperiences = [...exp].sort((a, b) => {
    return b.start._seconds - a.start._seconds;
  });
  console.log(sortedExperiences);

  return (
    <div className="projects">
      <ul>
        {sortedExperiences.map((experience) => (
          <li key={experience.id} className="clickable">
            <ExpItem
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              experience={experience}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Exp;
